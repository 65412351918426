import BreadCrumb from "@components/BreadCrumb";
import Layout from "@components/Layout";
import Navbar from "@components/Navbar";
import api from "@framework/api";
import { OnlineExchange } from "@framework/types/home";
import { IMenu } from "@framework/types/menu";
import Image from "next/image";
import Link from "next/link";

interface ErrorProps {
  onlineExchangeHouses: OnlineExchange[] | null;
  ads: any;
}

export default function Error({ onlineExchangeHouses, ads }: ErrorProps) {
  return (
    <>
      <div className="w-screen sm:w-full fondo404 h-[auto] grid grid-cols-1 ">
        <div className="col-span-1">
          <BreadCrumb section="Error" />
        </div>
        <div className="grid h-auto grid-cols-1 md:grid-cols-3 items-center text-center gap-4 md:gap-2">
          <p className="text-white col-span-2 font-bold text-[22px] md:text-[38px] md:px-14">
            Estás más perdido que Benja en la Guerra de las Galaxias
          </p>
          <button className="bg-[#009688] uppercase w-[175px] h-9 px-4 rounded-3xl shadow-sm shadow-black text-sm text-white font-medium justify-self-center md:justify-self-auto">
            <Link href="/">VOLVER AL INICIO</Link>
          </button>
        </div>
        <div className="w-full">
          <Image src="/img/page404.svg" width={970} height={676} alt="image error" />
        </div>
      </div>
    </>
  );
}

export const getStaticProps = async ({}) => {
  const { data: menu } = await api.getmenu();
  const { data: onlineExchangeHouses } = await api.getOnlineExchange();
  const { data: ads } = await api.getAds();
  const { data: clever } = await api.clever();
  const { data: ticker } = await api.getExchangeHouses({ ticker: 1 });


  return {
    revalidate: 60,
    props: {
      menus: menu,
      onlineExchangeHouses,
      ads,
      clever: clever.ads_status,
      ticker
    },
  };
};

Error.Layout = Layout;
